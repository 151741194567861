.a, .ab, .ac, .af, .ag, .ah, .ai, .al, .am, .an, .ao, .ap, .aq, .ar, .as, .av, .ax, .ay, .az, .ba, .bb, .bc, .be, .bf, .bg, .bh, .bv, .bw, .bx, .by, .bz, .c, .cc, .cd, .ce, .cf, .ci, .cr, .cv, .cx, .cy, .cz, .d, .db, .df, .dh, .dl, .dv, .dw, .dx, .dy, .dz, .ea, .ec, .ed, .ee, .ef, .k, .m, .o, .p, .r, .u, .w, .x, .y {
    fill: none;
}

/*Fill Innen*/
.b {
    fill: #4b636b;
    cursor:pointer;
}



/*Landesgrenzen*/
.c, .d, .m {
    stroke: #fff;
}

.ap, .az, .c, .cc, .cd, .ce, .d, .dv, .k, .m, .o, .p, .r {
    stroke-linejoin: round;
}

/*Landesgrenzen*/
.c {
    stroke-width: 0.758px;
    /*stroke-width: 10px;*/
}

/*Namen*/
.e, .l, .v {
    fill: #fff;
}

/*Namen*/
.e {
    font-size: 15.39px;
    font-family: SegoeUI, Segoe UI;
}

.f {
    letter-spacing: -0.03em;
}

.g {
    letter-spacing: -0.06em;
}

.h {
    clip-path: url(#a);
}

.au, .i {
    fill: #fcdd09;
}

.ad, .aj, .bk, .bm, .bo, .bp, .br, .bs, .cm, .cn, .dn, .do, .i, .j, .l, .n {
    fill-rule: evenodd;
}

.j, .q {
    fill: #da121a;
}

.af, .av, .ax, .ay, .az, .ba, .bb, .bc, .be, .bf, .bg, .bh, .cc, .cd, .ce, .cf, .ci, .cr, .cv, .cx, .cy, .cz, .db, .dl, .dv, .dw, .dx, .dy, .dz, .ec, .ed, .ee, .ef, .k, .u, .w, .x, .y {
    stroke: #000;
}

.ah, .az, .ba, .bg, .bh, .cc, .cd, .ce, .k, .m, .o, .p, .r {
    stroke-linecap: round;
}

.k {
    stroke-width: 0.535px;
}

.m {
    stroke-width: 0.178px;
}

.n {
    fill: #0f47af;
}

.o {
    stroke: #0f47af;
}

.o, .r {
    stroke-width: 0.089px;
}

.p {
    stroke: #fcdd09;
    stroke-width: 0.267px;
}

.r {
    stroke: #da121a;
}

.s {
    clip-path: url(#b);
}

.t {
    fill: #e30613;
}

.u {
    stroke-width: 0.56px;
}

.ab, .w {
    stroke-width: 0.14px;
}

.x {
    stroke-width: 0.28px;
}

.y {
    stroke-width: 0.42px;
}

.z {
    clip-path: url(#c);
}

.aa, .ad {
    fill: #ffe013;
}

.ab, .ac, .ag, .ah, .ai, .al, .am, .an, .ao, .ar, .as {
    stroke: #231f20;
}

.ab, .ac, .af, .ag, .ah, .ai, .al, .am, .an, .ao, .aq, .ar, .as {
    stroke-miterlimit: 3.856;
}

.ac, .af, .ah, .ai {
    stroke-width: 0.103px;
}

.ae, .do {
    fill: #ed1c24;
}

.ag {
    stroke-width: 0.12px;
}

.ai {
    stroke-linecap: square;
}

.aj, .ak {
    fill: #231f20;
}

.al {
    stroke-width: 0.035px;
}

.am {
    stroke-width: 0.088px;
}

.an {
    stroke-width: 0.066px;
}

.ao {
    stroke-width: 0.044px;
}

.ap, .aq {
    stroke: #ed1c24;
    stroke-width: 0.071px;
}

.ar, .bz {
    stroke-width: 0.094px;
}

.as {
    stroke-width: 0.4px;
}

.at {
    clip-path: url(#d);
}

.av, .ax, .ay, .ba {
    stroke-miterlimit: 2.414;
}

.av {
    stroke-width: 0.386px;
}

.aw {
    fill: #078930;
}

.ax {
    stroke-width: 0.209px;
}

.ay, .az {
    stroke-width: 0.325px;
}

.ba, .ec {
    stroke-width: 0.26px;
}

.bb {
    stroke-width: 0.536px;
}

.bc {
    stroke-width: 0.446px;
}

.bd {
    fill: #f3f2f2;
}

.be {
    stroke-width: 0.289px;
}

.bf {
    stroke-width: 0.368px;
}

.bg {
    stroke-width: 0.263px;
}

.bh {
    stroke-width: 0.315px;
}

.bi {
    clip-path: url(#e);
}

.bj {
    fill: #d00;
}

.bl {
    clip-path: url(#f);
}

.bm {
    fill: #a77f29;
}

.bn, .bs {
    fill: #f9d100;
}

.bo {
    fill: #ee1c2a;
}

.bp, .bq {
    fill: #d8201f;
}

.br {
    fill: #41b444;
}

.bt {
    fill: #292a2b;
}

.bu {
    fill: #efeeef;
}

.bv, .bw, .bx, .by, .bz {
    stroke: #efeeef;
    stroke-miterlimit: 2.613;
}

.bv {
    stroke-width: 0.082px;
}

.bw {
    stroke-width: 0.114px;
}

.bx {
    stroke-width: 0.097px;
}

.by {
    stroke-width: 0.095px;
}

.ca {
    clip-path: url(#g);
}

.cb {
    fill: #ffc900;
}

.cc {
    stroke-width: 0.136px;
}

.cd {
    stroke-width: 0.17px;
}

.ce {
    stroke-width: 0.238px;
}

.cf, .ci, .dl, .dw, .dx {
    stroke-miterlimit: 10;
}

.cf {
    stroke-width: 0.409px;
}

.cg {
    fill: #f33b1d;
}

.ch {
    clip-path: url(#h);
}

.ci {
    stroke-width: 0.271px;
}

.cj {
    fill: #e64625;
}

.ck {
    fill: #f5d306;
}

.cl {
    clip-path: url(#i);
}

.cm, .dt {
    fill: #fd0;
}

.cn {
    fill: #008444;
}

.co {
    clip-path: url(#j);
}

.cp {
    fill: #0094cb;
}

.cq {
    fill: #f0432a;
}

.cr {
    stroke-width: 0.161px;
}

.cs, .dn {
    fill: #fbc707;
}

.ct {
    clip-path: url(#k);
}

.cu {
    fill: #efcf4d;
}

.cv {
    stroke-width: 1.15px;
}

.cw {
    fill: #00529f;
}

.cx {
    stroke-width: 1.365px;
}

.cy {
    stroke-width: 1.317px;
}

.cz {
    stroke-width: 0.958px;
}

.da {
    fill: #e10000;
}

.db {
    stroke-width: 1.078px;
}

.dc {
    clip-path: url(#l);
}

.dd {
    clip-path: url(#n);
}

.de {
    fill: #c1001f;
}

.df {
    stroke: #d00;
}

.df, .dh {
    stroke-miterlimit: 3.864;
    stroke-width: 0.026px;
}

.dg {
    fill: #3f983e;
}

.dh {
    stroke: #060;
}

.di {
    fill: #fcef3c;
}

.dj {
    clip-path: url(#o);
}

.dk {
    clip-path: url(#p);
}

.dl {
    stroke-width: 0.072px;
}

.dm {
    fill: #176fc1;
}

.dp {
    clip-path: url(#q);
}

.dq {
    fill: #fc0;
}

.dr {
    fill: red;
}

.ds {
    clip-path: url(#r);
}

.du {
    fill: #0098d4;
}

.dv, .dw {
    stroke-width: 0.25px;
}

.dx {
    stroke-width: 0.221px;
}

.dy {
    stroke-width: 0.452px;
}

.dz {
    stroke-width: 0.039px;
}

.ea {
    stroke: #fd0;
    stroke-width: 0.521px;
}

.eb {
    fill: #0090cf;
}

.ed {
    stroke-width: 0.454px;
}

.ee {
    stroke-width: 0.364px;
}

.ef {
    stroke-width: 0.393px;
}

/*Anpassungen Haase*/
.saarland:hover, .berlin:hover{
    /*fill:#fff;*/
}



.saarland-hover,
.bw-hover,
.bayern-hover,
.rpf-hover,
.hessen-hover,
.thueringen-hover,
.nrw-hover,
.brandenburg-hover,
.niedersachsen-hover,
.sa-hover,
.sachsen-hover,
.sh-hover,
.mcpom-hover,
.bremen-hover,
.berlin-hover,
.hamburg-hover{
    /*fill:#fff;*/
    /*fill: #4b636b;*/
    fill:rgb(75, 99, 137)
    /*fill:rgb(255,255,255)*/
}

.saarland,
.bw,
.bayern,
.rpf,
.hessen,
.thueringen,
.nrw,
.brandenburg,
.niedersachsen,
.sa,
.sachsen,
.sh,
.mcpom,
.bremen,
.berlin,
.hamburg
{
    fill: #4b636b;
    cursor:pointer;
}

/*.bw,.bw-hover{*/
.bw-hover{
    /*fill: #fff;*/
}

.bwtest{
    /*fill: #000 !important;*/
    /*display:none*/
}
