@charset "UTF-8";
@font-face {
  font-family: "Sarabun";
  src: url("./../assets/fonts/Sarabun/Sarabun-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Sarabun";
  src: url("./../assets/fonts/Sarabun/Sarabun-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Sarabun";
  src: url("./../assets/fonts/Sarabun/Sarabun-ExtraBold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Sarabun";
  src: url("./../assets/fonts/Sarabun/Sarabun-ExtraBold.ttf");
  font-weight: 900;
}
:root {
  /* Colors: */
  --unnamed-color-ff00ff: #FF00FF;
  --unnamed-color-00ff00: #00FF00;
  --unnamed-color-da121a: #DA121A;
  --europa-gelb: #FFCC00;
  --europa-blau: #003399;
  --unnamed-color-00339926: #00339926;
  --schwarz-333: #333333;
  --grau-dunkel: #707070;
  --weiss: #FFFFFF;
  --unnamed-color-000000: #000000;
  /* Font/text values */
  --unnamed-font-family-segoe-ui: Sarabun;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-600: 600;
  --unnamed-font-weight-900: 900;
  --unnamed-font-weight-normal: normal;
  --unnamed-font-weight-bold: bold;
  --unnamed-font-size-14: 14px;
  --unnamed-font-size-16: 16px;
  --unnamed-font-size-17: 17px;
  --unnamed-font-size-18: 18px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-22: 22px;
  --unnamed-font-size-26: 26px;
  --unnamed-font-size-30: 30px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-1: 1px;
  --unnamed-character-spacing-0-7: 0.7px;
  --unnamed-character-spacing-0-8: 0.8px;
  --unnamed-character-spacing-0-85: 0.85px;
  --unnamed-character-spacing-0-9: 0.9px;
  --unnamed-character-spacing-1-8: 1.8px;
  --unnamed-character-spacing-1-1: 1.1px;
  --unnamed-character-spacing-1-5: 1.5px;
  --unnamed-line-spacing-19: 19px;
  --unnamed-line-spacing-21: 21px;
  --unnamed-line-spacing-22: 22px;
  --unnamed-line-spacing-24: 24px;
  --unnamed-line-spacing-27: 27px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-35: 35px;
  --unnamed-line-spacing-40: 40px;
  --unnamed-text-transform-uppercase: uppercase;
}

/* Character Styles */
.karte-tabelle-17pt-schwrz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-17);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-85);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.pt26-regular-gelb {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-26);
  line-height: var(--unnamed-line-spacing-35);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--europa-gelb);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.pt18-bold {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.pt18-regular-schwarz {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-000000);
}

.bundesland-20pt-semibold-weiss-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-27);
  letter-spacing: var(--unnamed-character-spacing-1);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.h1-30pt-bold-weiss {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-1-5);
  color: var(--weiss);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.impressum-30pt-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-40);
  letter-spacing: var(--unnamed-character-spacing-1-5);
  color: var(--schwarz-333);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.archiv-14pt-semibold-weiss {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-7);
  color: var(--weiss);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.h2-22pt-bold-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-22);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-1-1);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.h3-18pt-bold-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-1-8);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.h3-18pt-bold-schwarz {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-9);
  color: var(--unnamed-color-000000);
}

.h3-18pt-bold-weiss-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-27);
  letter-spacing: var(--unnamed-character-spacing-1);
  color: var(--weiss);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.datum-18pt-black-blau {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-900);
  font-size: var(--unnamed-font-size-18);
  line-height: var(--unnamed-line-spacing-24);
  letter-spacing: var(--unnamed-character-spacing-0-9);
  color: var(--europa-blau);
}

.ort-16pt-semibold-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.ort-16pt-bold-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-bold);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  color: var(--unnamed-color-000000);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.fließtexti-16pt-regular-schwarz {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-22);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  color: var(--schwarz-333);
}

.pt16-regilar-weiss {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  color: var(--weiss);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.kontaktdaten-16pt-semibild-weiss {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-16);
  line-height: var(--unnamed-line-spacing-21);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  color: var(--weiss);
}

.foto-14pt-regular-schwarz {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-normal);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-7);
  color: var(--unnamed-color-000000);
}

.link-14pt-semibild-schwarz-upper {
  font-family: var(--unnamed-font-family-segoe-ui);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-600);
  font-size: var(--unnamed-font-size-14);
  line-height: var(--unnamed-line-spacing-19);
  letter-spacing: var(--unnamed-character-spacing-0-7);
  color: var(--schwarz-333);
  text-transform: var(--unnamed-text-transform-uppercase);
}

a:hover {
  color: var(--europa-blau) !important;
}

body {
  background-color: var(--europa-blau) !important;
  color: var(--weiss) !important;
  padding-bottom: 70px;
}

.fullwidth {
  max-width: unset !important;
}

.container, .container-fluid {
  max-width: 1322px;
}

.container-karte {
  max-width: 1388px !important;
}

.color-white {
  color: var(--weiss);
}

.color-black {
  color: var(--schwarz-333);
}

.bg-white {
  background-color: var(--weiss);
}

.bg-europablau {
  background-color: var(--europa-blau) !important;
}

.bg-europagelb {
  background-color: var(--europa-gelb) !important;
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.mt-15 {
  margin-top: -15px !important;
}

.cursor-pointer {
  cursor: pointer;
}

#kartelink {
  text-align: center;
  width: 120px;
  height: 47px;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.3019607843);
  border-radius: 0 0 8px 8px !important;
  background: var(--weiss) 0% 0% no-repeat padding-box;
  opacity: 1;
  transition: 0.3s ease-out;
}

#kartelink:hover {
  background: var(--europa-gelb) 0% 0% no-repeat padding-box;
}

#tabellelink {
  text-align: center;
  width: 120px;
  height: 47px;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.3019607843);
  border-radius: 0 0 8px 8px !important;
  background: var(--weiss) 0% 0% no-repeat padding-box;
  opacity: 1;
  transition: 0.3s ease-out;
}

#tabellelink:hover {
  background: var(--europa-gelb) 0% 0% no-repeat padding-box;
}

#pfeillink {
  text-align: center;
  width: 60px;
  height: 47px;
  box-shadow: 0 3px 6px rgba(51, 51, 51, 0.3019607843);
  border-radius: 0 0 8px 8px !important;
  background: var(--weiss) 0% 0% no-repeat padding-box;
  opacity: 1;
  transition: 0.3s ease-out;
  z-index: 1100;
  position: relative;
}

#pfeillink:hover {
  background: var(--europa-gelb) 0% 0% no-repeat padding-box;
}

.modal-content {
  background-color: transparent !important;
}

.weitere-details {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-segoe-ui);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 8px !important;
  background-color: var(--europa-gelb) !important;
  transition: 0.3s ease-out;
  color: #000;
  border: 0 !important;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.3019607843);
  width: 185px;
  height: 45px;
}

.weitere-details:hover {
  background-color: var(--europa-blau) !important;
  color: var(--weiss);
  transition: 0.3s ease-out;
}

.weitere-details > span {
  transition: 0.3s ease-out;
}

.weitere-details:hover > span {
  color: #fff;
  transition: 0.3s ease-out;
}

.shariff {
  position: fixed;
  bottom: 10px;
  left: 50%;
  z-index: 1000;
  width: 200px;
  margin-left: -14px;
}
.shariff .fa-facebook-f {
  color: transparent;
  background-image: url("../assets/06_Footer/facebook.svg");
  background-repeat: no-repeat;
}
.shariff .fa-facebook-f:hover {
  background-image: url("../assets/06_Footer/facebook-hover.svg");
}
.shariff a {
  background-color: transparent !important;
}
.shariff .fa-twitter {
  color: transparent;
  background-image: url("../assets/06_Footer/twitter.svg");
  background-repeat: no-repeat;
}
.shariff .fa-twitter:hover {
  background-image: url("../assets/06_Footer/twitter-hover.svg");
}

@media only screen and (min-width: 360px) {
  .shariff .orientation-horizontal li.facebook {
    position: relative;
    left: 34px;
  }
}
.modal-dialog {
  max-width: unset !important;
  z-index: 100000 !important;
  border: 0;
  top: 74px;
  margin: 0 !important;
  margin-top: 28px !important;
}

.modal-content {
  border: 0 !important;
}

.laenderbutton:hover {
  background-color: var(--europa-gelb) !important;
  transition: 0.3s ease-out;
}

.laenderbutton-hover {
  background-color: var(--europa-gelb) !important;
  transition: 0.3s ease-out;
}

.karteh1 {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-26)/var(--unnamed-line-spacing-35) var(--unnamed-font-family-segoe-ui);
  color: var(--unnamed-color-000000);
  text-align: center;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.goleft {
  position: relative;
}

.goleft:hover {
  left: -5px;
}

.goright {
  position: relative;
}

.goright:hover {
  left: 5px;
}

.goleftimage:hover > .pfeilgoleft {
  position: relative;
  left: -5px;
}

zindex-1500 {
  z-index: 1500 !important;
}

.jugendjahrbackdrop {
  background-color: #00FF00 !important;
  opacity: 0.8 !important;
  z-index: 1500 !important;
}

.jugendjahr {
  top: 150px;
}

.jugendjahrframe {
  z-index: 1600 !important;
}

.h-110 {
  height: 110px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.me-2-2rem {
  margin-right: 2.2rem !important;
}

.pt-2-6-rem {
  padding-top: 2.6rem;
}

hr {
  opacity: 1 !important;
}

.mehrzurew {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-segoe-ui);
  letter-spacing: var(--unnamed-character-spacing-0-8);
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 8px !important;
  transition: 0.3s ease-out;
  border: 0 !important;
  box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.3019607843);
  width: auto;
  height: 45px;
}

.mehrzurew:hover {
  background-color: var(--europa-blau) !important;
  color: var(--weiss) !important;
  transition: 0.3s ease-out;
}

.mehrzurew > span {
  transition: 0.3s ease-out;
}

.mehrzurew:hover > span {
  color: #fff;
  transition: 0.3s ease-out;
}

.line-height-22 {
  line-height: 22px !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.hover-white:hover .hover-target {
  color: #fff !important;
}

.no-hover:hover {
  color: inherit !important;
}

.modaljugendjahr {
  width: 992px !important;
  height: 517px !important;
}

@media only screen and (max-width: 575px) {
  .logowoche {
    max-width: 80px !important;
  }

  #pfeillink {
    width: 45px;
  }

  #pfeillink img {
    width: 25px;
  }

  #kartelink, #tabellelink {
    width: 60px !important;
  }

  .kartelinktext {
    font-size: 8px !important;
  }

  .kartenbutton {
    padding: 0 !important;
    margin: 0 !important;
    height: 30px !important;
  }

  .logoline img {
    max-width: 30px;
    float: left;
  }

  .header-font-size {
    font-size: 14px;
    line-height: 15px;
  }

  .header-slogan {
    font-size: 16px;
  }

  .modaljugendjahr {
    width: 100% !important;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .tabelle-wappen {
    max-width: 30px;
  }

  .tabelle-land {
    font-size: 14px;
    line-height: 18px;
  }

  .mobilefooterimg1, .mobilefooterimg2 {
    width: 100px;
  }

  .mobilefooterimg3 {
    width: 50px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 51px;
    left: 50%;
    width: 75px;
  }
  .shariff .fa-facebook-f, .shariff .fa-twitter {
    background-size: 20px 20px;
  }

  .h1tabelle {
    font-size: 15px;
  }

  .menuicon {
    width: 49px;
    height: auto;
  }

  .headericon {
    width: 100px;
  }

  .ps-20 {
    padding-left: 0px !important;
  }

  .pfeilheaderbundesland {
    height: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .logowoche {
    max-width: 200px !important;
  }

  #kartelink {
    width: 60px !important;
  }

  .kartelinktext {
    font-size: 16px !important;
  }

  .logoline img {
    max-width: 84px;
    float: left;
  }

  .header-slogan {
    font-size: 20px;
  }

  .header-font-size {
    font-size: 17px;
    line-height: 20px;
  }

  .modaljugendjahr {
    width: 100% !important;
    height: 517px !important;
    overflow: hidden;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .modaljugendjahr {
    width: 100% !important;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .mobilefooterimg1, .mobilefooterimg2 {
    width: 100px;
  }

  .mobilefooterimg3 {
    width: 50px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 52px;
    left: 50%;
    width: 75px;
  }
  .shariff .fa-facebook-f, .shariff .fa-twitter {
    background-size: 20px 20px;
  }

  .h1tabelle {
    font-size: 22px;
  }

  .menuicon {
    width: 49px;
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .logowoche {
    max-width: 200px !important;
  }

  .logoline img {
    max-width: 120px;
    float: left;
  }

  .header-slogan {
    font-size: 16px;
  }

  .modaljugendjahr {
    width: 100% !important;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .mobilefooterimg1, .mobilefooterimg2 {
    width: 150px;
  }

  .mobilefooterimg3 {
    width: 90px;
  }

  .header-slogan {
    font-size: 26px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 74px;
    left: 50%;
    width: 75px;
  }
  .shariff .fa-facebook-f, .shariff .fa-twitter {
    background-size: 20px 20px;
  }

  .h1tabelle {
    font-size: 20px;
  }

  .menuicon {
    width: 49px;
    height: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logoline img {
    max-width: 170px;
    float: left;
  }

  .header-slogan {
    font-size: 22px;
  }

  .header-font-size {
    font-size: 20px;
    line-height: 22px;
  }

  .modaljugendjahr {
    width: 100% !important;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .mobilefooterimg1, .mobilefooterimg2 {
    width: 150px;
  }

  .mobilefooterimg3 {
    width: 90px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 75px;
    left: 50%;
    width: 75px;
  }
  .shariff .fa-facebook-f, .shariff .fa-twitter {
    background-size: 20px 20px;
  }

  .h1tabelle {
    font-size: 22px;
  }

  .menuicon {
    width: 49px;
    height: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .logoline img {
    max-width: 170px;
    float: left;
  }

  .header-slogan {
    font-size: 22px;
  }

  .modaljugendjahr {
    width: 100% !important;
  }

  .modaljugendjahr img {
    width: 100%;
  }

  .mobilefooterimg1, .mobilefooterimg2 {
    width: 190px;
  }

  .mobilefooterimg3 {
    width: 110px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 90px;
    left: 50%;
    width: 75px;
  }
  .shariff .fa-facebook-f, .shariff .fa-twitter {
    background-size: 20px 20px;
  }
}
@media only screen and (min-width: 1400px) {
  .lf1, .lf2 {
    width: 179px;
  }

  .lf3 {
    width: 88px;
  }

  .instalogo {
    width: 20px;
    position: relative;
    left: -75px;
  }

  .shariff {
    position: fixed;
    bottom: 11px;
    left: calc(50% + 94px);
    width: 75px;
  }
}
.border-radius8 {
  border-radius: 0 0 8px 8px !important;
}

.hover-333:hover {
  color: #333 !important;
}

.hover-000:hover {
  color: #333 !important;
}

.morelinks {
  margin-top: 5px;
}

.morelinks a {
  color: #333 !important;
  text-decoration: none;
  background-image: url("../assets/05_Detailseite/pf-link.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-left: 30px;
}

.morelinks a:hover {
  background-position: 4px 0;
}

.morelinksimpressum {
  margin-top: 5px;
}

.morelinksimpressum a {
  color: #333 !important;
  text-decoration: none;
  background-image: url("../assets/05_Detailseite/pf-link.svg");
  background-repeat: no-repeat;
  background-position: 20px 0;
  padding-left: 50px;
}

.morelinksimpressum a:hover {
  background-position: 25px 0;
}

.logowochemobil, .jjmobil {
  max-height: 140px;
  width: auto !important;
}
